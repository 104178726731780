<!--  -->
<template>
  <div class="">
    <div class="historychoose" >
      <!-- <el-tabs :tab-position="tabPosition" type="border-card">
  <el-tab-pane label="单导联心电" class="history_tab"> -->
      <h2>请选择日期范围查询<span>{{ realName }}</span><span style="color:#4da1eb">血糖</span>检测记录</h2>
      <el-form ref="form" :model="form">
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择起始日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择截止日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date2" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <el-button class="xingou-blue" type="primary" @click="querylistByTime()">确认查询</el-button>
          <el-button type="primary" plain @click="queryAll()">查询所有记录</el-button>
        </el-form-item>
      </el-form>

      <!-- 添加搜索\刷新功能 -->
      <!-- <div style="text-align: right">
        <el-button circle size="small"><i class="el-icon-search"></i></el-button>
        <el-button circle size="small"><i class="el-icon-refresh"></i></el-button>
        <el-button circle size="small"><i class="el-icon-s-grid"></i></el-button>
      </div> -->
      <!-- 表格内容 -->
      <el-table :data="tableData" height="600" :header-cell-style="tableHeaderColor" border stripe style="width: 100%" 
      :cell-style="{fontSize:'16px'}" v-loading="loadingShow" element-loading-text="数据正在加载中" :default-sort = "{prop: 'date', order: 'descending'}">
        <el-table-column type="index" align="center">
        </el-table-column>
        <el-table-column prop="date" label="记录时间" align="center" sortable>
        </el-table-column>
        <el-table-column prop="value" label="血糖值(mmol/L)" align="center">
        </el-table-column>
        <el-table-column prop="feature" label="结论" align="center">
        </el-table-column>
        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <!-- <el-button
            size="mini"
            type="danger"
            @click.native.prevent="handleDelete(scope.$index, tableData)">删除</el-button> -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDelete(scope.$index, scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- </el-tab-pane>
  </el-tabs> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
      form: {
        date1: '',
        date2: '',
      },
      former: '2022-01-01',
      latter: '2022-08-06',
      tableData: [],
      loadingShow: true,
      realName:'',
    };
  },
  computed: {
  },
  methods: {
    TimeProcess(time) {
      let y = time.getFullYear();
      let MM = time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = time.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + MM + '-' + d
    },
    querylistByTime() {
      this.loadingShow = true
      this.startTime = this.TimeProcess(this.form.date1)
      this.endTime = this.TimeProcess(this.form.date2)
      console.log(this.startTime)
      console.log(this.endTime)
      this.$http({
        url: this.$http.adornUrl("/personal/personalbloodsugar/listByTime"),
        method: "get",
        params: this.$http.adornParams({ uid: this.uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据");
          console.log(data);
          this.tableData = data.staticEcgEntities;
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    queryAll() {
      this.loadingShow = true
        this.$http({
        url: this.$http.adornUrl("/personal/personalbloodsugar/list"),
        method: "get",
        params: this.$http.adornParams({ uid: this.uid }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据");
          console.log(data)
          this.datalist = data.personalBloodSugarEntities;
          this.tableData = new Array(this.datalist.length)
          this.tableData = this.datalist.reverse()


        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    //删除功能。目前简单用假数据代替，还不涉及数据库链接
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //设置表头行的样式
    tableHeaderColor() {
      return "color:#000 ;font-size:18px;text-align:center";
    },
    getinfo() {
      this.$http({
        url: this.$http.adornUrl('/personal/info/getBasicInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          this.realName = data.realName
          // this.age = data.age
          // this.gender = data.gender
          // var genderMap = { 0: "女", 1: "男" }
          // this.gender = genderMap[this.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    }
  },
  created() {
    this.uid = this.$store.state.personal_module.uid
  },
  mounted() {
    this.queryAll()
    this.getinfo()

  },
};
</script>
<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}

.form-name {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}
.xingou-blue{
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.xingou-blue:hover,
.xingou-blue:focus {
  background: #4da1eb;
  border-color: #4da1eb;
  color: #fff
}
</style>